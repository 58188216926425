// import
import PageMeta from "../../functions/PageMeta/PageMeta";


// assets
import style from "./About.module.scss";

import Photo01 from "../../assets/images/about-center01.jpg";
import Photo02 from "../../assets/images/about-center02.jpg";
import Photo03 from "../../assets/images/about-center03.jpg";
import Photo04 from "../../assets/images/about-center04.jpg";

const photos = [
  {
    src: Photo01,
    alt: "1:1 필라테스룸",
  },
  {
    src: Photo02,
    alt: "1:1, 2:1 요가룸",
  },
  {
    src: Photo03,
    alt: "대기공간",
  },
  {
    src: Photo04,
    alt: "6:1 소그룹 요가룸",
  }
]

// About
function About() {
  return (
    <>
      <PageMeta where={location} />

      <main id={style.about}>
        <header className={style["sec-header"]}>
          <div className="container">
            <h2 className="common-headline">About</h2>

            <div className={style.description}>
              <p>처음부터 끝까지 원장 선생님이 1:1로 직접 케어해드려요.</p>

              <p>
                처음 방문했을 때 상담자와 실제로 수업하는 강사가 달라서 당혹스러우셨던 적이 있나요?
                <br />
                <br />상담과 재등록할 때만 나타나는 원장,
                <br />상담 시에 들었던 내용과 실제 수업이 다르거나, 강사와 성향이 맞지 않아서 흥미를 느끼지 못하는 경우가 없으셨나요?
                <br />
                <br />아이나 요가&필라테스에서는 8년 차 경력의 원장이 상담, 체형 측정, 수업까지 회원님의 처음과 끝을 책임집니다.
                <br />상담을 통해서 목적과 건강 상태를 파악하고, 정적/동적 체형 측정과 컨설팅을 통해 신체 패턴과 가동 범위를 체크합니다.
                <br />이를 바탕으로 회원님의 성향에 맞춰 요가, 필라테스, 애니멀 플로우, 유산소 등 수업 커리큘럼을 구성하고 지도합니다.
              </p>
            </div>
          </div>
        </header>

        <section className={style["sec-process"]}>
          <div className="container">
            <header className={style.header}>
              <h3 className="common-headline">Process</h3>

              <p className={style.description}>
                체형 분석과 컨설팅을 진행하여 방향성을 정립하고 운동 목적과 체형, 컨디션에 맞춰 개별적인 특성에 맞게 프로그램을 설계하고 진행합니다.
                <br />바쁜 일상 속 소중한 시간, 오직 나만을 위한 프로그램으로 나를 더 사랑하고 몸과 마음이 건강해질 수 있도록 도와드려요.
              </p>
            </header>

            <ol className={style.process} role="list">
              <li role="listitem">
                <strong>1. 상담</strong>

                <p>
                  나는 어떤 스타일의 운동을 좋아하는지, 선생님과 내가 잘 맞는지가 가장 중요하지요. 앞으로 운동을 지도하고 꾸준히 함께해줄 원장 선생님과 상담을 통해 운동 계획을 세워보아요.
                </p>
              </li>

              <li role="listitem">
                <strong>2. 체형 측정</strong>

                <p>
                  현재 나의 상태를 잘 알고 있어야 다치지 않고 꾸준히 운동 할 수 있어요. 체형 측정을 통해 평소 생활 습관과 전반적인 근력의 균형을 살펴봅니다.
                </p>
              </li>

              <li role="listitem">
                <strong>3. 운동 선택</strong>

                <p>
                  요가, 필라테스, 혼합수업 중 좋아하는 운동을 직접 고르거나 골고루 모두 수업을 들어볼 수 있어요. 나의 성향에 맞는 운동을 직접 경험하며 찾아보세요.
                </p>
              </li>

              <li role="listitem">
                <strong>4. 첫 수업</strong>

                <p>
                  첫 번째 수업에서는 동적 체형 측정을 통해 가동 범위를 체크하고, 호흡하는 방법부터 차근차근 배울 거예요. 운동 경험이 없는 분들도 따라오실 수 있도록 기초부터 안내합니다.
                </p>
              </li>

              <li role="listitem">
                <strong>5. 피드백과 관리</strong>

                <p>
                  서서히 몸에서 일어나는 건강한 변화를 느껴보세요. 몸과 함께 마음도 가벼워질 거예요. 다른 수업이 궁금해지면 수업을 변경할 수도 있어요.
                </p>
              </li>
            </ol>
          </div>
        </section>

        <section className={style["sec-center"]}>
          <div className="container">
            <h3 className="common-headline">Center</h3>

            <ul>
              {photos.map((photo, index) => {
                return (
                  <li key={index}>
                    <img
                      loading="lazy"
                      height="400"
                      src={photo.src}
                      alt=""
                    />
                    <span className={style.label}>{photo.alt}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </section>
      </main>
    </>
  );
}

export default About;
