// import
import React from "react";
import ReactDOM from "react-dom/client";
import { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { HelmetProvider } from "react-helmet-async";

// assets
import "./assets/scss/core.scss";

// components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Banner from "./components/Banner/Banner";

// pages
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Instructor from "./pages/Instructor/Instructor";
import Classes from "./pages/Classes/Classes";
import Contact from "./pages/Contact/Contact";

// react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

// App
function App() {
  // URL 끝에 /가 없다면 붙이기
  const pathnameSplit = location.pathname.split("/");
  if ( pathnameSplit[pathnameSplit.length - 1] !== "" ) {
    window.location.replace(`${location.href}/`);
  }

  // 하단 배너
  const root = document.documentElement;
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const closeBanner = () => {
    setIsBannerVisible(false);
  };

  if ( isBannerVisible ) {
    root.classList.add("is-banner-active");
  } else {
    root.classList.remove("is-banner-active");
  }

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <HelmetProvider>
            <Header />

            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/about/" element={<About />}></Route>
              <Route path="/instructor/" element={<Instructor />}></Route>
              <Route path="/classes/" element={<Navigate replace to="/classes/personal/" />}></Route>
              <Route path="/classes/personal/" element={<Classes now="personal" />}></Route>
              <Route path="/classes/couple/" element={<Classes now="couple" />}></Route>
              <Route path="/classes/group/" element={<Classes now="group" />}></Route>
              <Route path="/contact/" element={<Contact />}></Route>
            </Routes>

            <Footer />

            { isBannerVisible && <Banner onClose={closeBanner} /> }
          </HelmetProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  )
}

// render
const rootElement = document.getElementById("wrap");
const root = ReactDOM.createRoot(document.getElementById("wrap"));

if ( rootElement.hasChildNodes() ) {
  ReactDOM.hydrateRoot(rootElement, <App />);
} else {
  root.render(<App />);
}
