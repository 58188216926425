// import
import PropTypes from "prop-types";

// assets
import style from "./Banner.module.scss";

import { ReactComponent as LogoSymbol } from "../../assets/svgs/logo-symbol.svg";
import { ReactComponent as LogoKrFull } from "../../assets/svgs/logo-kr-full.svg";
import IcoClose from "../../assets/svgs/icon-close.svg";

// Banner
function Banner({onClose}) {
  return (
    <div className={style.banner}>
      <div className="container">
        <a
          href="https://map.naver.com/p/entry/place/1417743288?c=15.00,0,0,0,dh&placePath=/review"
          className={style.content}
          target="_blank" rel="noreferrer"
        >
          <div className={style.logos}>
            <LogoSymbol className={style.symbol} />
            <LogoKrFull className={style.full} />
          </div>

          <div className={style.label}>
            <span>리뷰 이벤트</span>
          </div>

          <div className={style.text}>
            SNS에 리뷰를 남겨주시면 요가&필라테스 토삭스를 드려요!
          </div>
        </a>

        <button
          className={style["banner-close"]}
          type="button"
          aria-label="이벤트 배너 닫기"
          onClick={onClose}
        >
          <img src={IcoClose} alt="" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}

Banner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Banner;
