// import
import { Helmet } from "react-helmet-async";
import { PAGE_META } from "../../config"

// PageMeta
function PageMeta(where) {
  let path = where.where.pathname;

  return (
    <Helmet key={where.where.key}>
      <title>{`${PAGE_META[path]["title"]}`}</title>
      <meta name="description" content={`${PAGE_META[path]["og"]["description"]}`} />

      <link rel="canonical" href={`${PAGE_META[path]["canonical"]}`} />

      <meta property="og:title" content={`${PAGE_META[path]["og"]["title"]}`} />
      <meta property="og:description" content={`${PAGE_META[path]["og"]["description"]}`} />
      <meta property="og:url" content={`${PAGE_META[path]["og"]["url"]}`} />
    </Helmet>
  )
}

export default PageMeta;
