// import
import { Link } from "react-router-dom";
import { TITLE, LOCATION_SHORT } from "../../config";

// assets
import style from "./Footer.module.scss";

import IcoMap from "../../assets/svgs/icon-map.svg";

// Footer
function Footer() {
  return (
    <footer id={style.footer}>
      <h2 className="blind">Footer</h2>

      <div className="container">
        <ul role="list">
          <li role="listitem">
            <strong className={style.title}>
              {TITLE}
            </strong>
          </li>

          <li role="listitem">
            <a href="https://naver.me/xxRgh8ce" target="_blank" rel="noreferrer">
              {LOCATION_SHORT}
            </a>
          </li>

          <li role="listitem">
            사업자등록번호 357-27-01727
          </li>

          <li role="listitem" className={style.contact}>
            <Link to="contact/">
              <img
                src={IcoMap}
                width="18"
                height="18"
                alt=""
                aria-hidden="true"
              />
              Contact Us
            </Link>
          </li>

          <li role="listitem" className={style.copyright}>
            (C) 2024. <strong>아이나 요가&필라테스</strong> All Rights Reserved.
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer;
