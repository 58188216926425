// import
import { NavLink } from "react-router-dom";

// assets
import style from "./Classes.module.scss";
import ClassesContent from "./Classes.Content";

// classes
function Classes(now) {
  return (
    <>
      <main id={style.classes}>
        <header className={style["sec-header"]}>
          <div className="container">
            <h2 className="common-headline">Classes</h2>
          </div>
        </header>

        <section className={style["sec-classes"]}>
          <div className={style["tab-buttons"]}>
            <div className="container">
              <ul role="list">
                <li role="listitem">
                  <NavLink
                    to="/classes/personal/"
                    className={({isActive}) => isActive ? style.active : ""}
                  >
                    <span>
                      1:1
                      <br />Personal <br className="common-br-mo" />Yoga/Pilates
                    </span>
                  </NavLink>
                </li>

                <li role="listitem">
                  <NavLink
                    to="/classes/couple/"
                    className={({isActive}) => isActive ? style.active : ""}
                  >
                    <span>
                      2:1
                      <br />Couple <br className="common-br-mo" />Yoga
                    </span>
                  </NavLink>
                </li>

                <li role="listitem">
                  <NavLink
                    to="/classes/group/"
                    className={({isActive}) => isActive ? style.active : ""}
                  >
                    <span>
                      6:1
                      <br />Group <br className="common-br-mo" />Yoga
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          {
            <ClassesContent content={now} />
          }
        </section>
      </main>
    </>
  );
}

export default Classes;
