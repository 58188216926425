// import
import PropTypes from "prop-types";

// assets
import style from "./NaverCounselButton.module.scss";

import IcoNaver from "../../assets/images/ico-naver.png";

// NaverCounselButton
function NaverCounselButton({url}) {
  return (
    <a
      href={url}
      target="_blank"
      className={style.button}
      rel="noreferrer"
    >
      <img
        src={IcoNaver}
        alt=""
        aria-hidden="true"
        width={24}
        height={24}
      />
      네이버 상담 예약하기
    </a>
  )
}

NaverCounselButton.propTypes = {
  url: PropTypes.string
}

export default NaverCounselButton;
